import React, { useState } from 'react';
import { Link } from 'gatsby';
import Header from '../components/header11'
import Footer from '../components/footer4'
import { graphql, StaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import Img from 'gatsby-image'
import Sidebar from '../components/sidebar'

const pageLimit = 3
export default function Blog({
	data, // this prop will be injected by the GraphQL query below.
  }) {
	// console.log(data)
	// const { markdownRemark } = data.page // data.markdownRemark holds your post data

	// const { frontmatter, html } = markdownRemark
	// // let featuredImgFluid = frontmatter.featuredImage.childImageSharp.fluid
		const [curPage,setPage] = useState(1);
		var numPages = parseInt((data.page.edges.length+pageLimit-1)/pageLimit)
		if(data.page.edges.length)
        return (
            <>
                <Header/>

                <div class="page-content bg-white">
                    
					<BackgroundImage
                        // className="dlab-bnr-inr "
						fluid={data.desktop.childImageSharp.fluid}
						placeholderStyle = {{opacity:0.5}}
						alt = "DN Pack Office"
                        > 
						<div class="dlab-bnr-inr overlay-black-middle ">
					   <div class="container">
                            <div class="dlab-bnr-inr-entry">
                                <h1 class="text-white">Blog</h1>
                                <div class="breadcrumb-row">
                                    <ul class="list-inline">
                                        <li><Link to="/index">Home</Link></li>
                                        <li>Blog</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
						</div>
						</BackgroundImage>
                   
                    <div class="content-area">
						<div class="container">
							{data.page.edges.map((page,idx) =>{
								console.log(page)
									if(parseInt(idx/pageLimit) == curPage-1){
										return(
											<div class="blog-post blog-md clearfix">
												<div class="dlab-post-media"> 
													<a href={page.node.frontmatter.slug}>
													<Img fluid={page.node.frontmatter.featuredImage.childImageSharp.fluid} alt="DN Pack Employee" className="img-cover"/>

													</a> 
												</div>
												<div class="dlab-post-info">
													<div class="dlab-post-meta">
														<ul>
															<li class="post-date"> <span>{page.node.frontmatter.date}</span> </li>
															<li class="post-author"> By <a href={page.node.frontmatter.slug}>{page.node.frontmatter.author}</a> </li>
														</ul>
													</div>
													<div class="dlab-post-title">
														<h4 class="post-title"><a href={page.node.frontmatter.slug}>{page.node.frontmatter.title}</a></h4>
													</div>
													<div class="dlab-post-text">
														<p>{page.node.frontmatter.summary}</p>
													</div>
													<div class="dlab-post-readmore"> 
														<a href={page.node.frontmatter.slug} title="READ MORE" rel="bookmark" class="site-button">Read The Article
															<i class="ti-arrow-right"></i>
														</a>
													</div>
												</div>
											</div>

										);
									}
								}
							)}
							{/* <div class="blog-post blog-md clearfix">
								<div class="dlab-post-media"> 
									<a href="/blog">
									<Img fluid={data.emp.childImageSharp.fluid} alt="DN Pack Employee" className="img-cover"/>

									</a> 
								</div>
								<div class="dlab-post-info">
									<div class="dlab-post-meta">
										<ul>
											<li class="post-date"> <strong>10 Aug</strong> <span> 2020</span> </li>
											<li class="post-author"> By <a href="/blog">Nhat</a> </li>
										</ul>
									</div>
									<div class="dlab-post-title">
										<h4 class="post-title"><a href="/blog">The Story Of Duy Nhat Pack</a></h4>
									</div>
									<div class="dlab-post-text">
										<p>Duy Nhat trading and manufacturing company was establihsed in the year 2004. The company specializes in printing & converting paper and soft packaging mainly for food industry such as frozen seafood, agro products and consumer goods…</p>
									</div>
									<div class="dlab-post-readmore"> 
										<a href="/blog" title="READ MORE" rel="bookmark" class="site-button">Read The Article
											<i class="ti-arrow-right"></i>
										</a>
									</div>
								</div>
							</div>



							<div class="blog-post blog-md clearfix">
								<div class="dlab-post-media"> 
									<a href="/blog">
									<Img fluid={data.top.childImageSharp.fluid} alt="DN Pack Factory" className="img-cover"/>

									</a> 
								</div>
								<div class="dlab-post-info">
									<div class="dlab-post-meta">
										<ul>
											<li class="post-date"> <strong>11 Aug</strong> <span> 2020</span> </li>
											<li class="post-author">By <a href="/blog">Nhat</a> </li>
										</ul>
									</div>
									<div class="dlab-post-title">
										<h4 class="post-title"><a href="/blog">New Factory Opening</a></h4>
									</div>
									<div class="dlab-post-text">
										<p>Our new factory in An Nghiep Industrial has been completed and fully operational.</p>
									</div>
									<div class="dlab-post-readmore"> 
										<a href="/blog" title="READ MORE" rel="bookmark" class="site-button">Read The Article
											<i class="ti-arrow-right"></i>
										</a>
									</div>
								</div>
							</div>
							<div class="blog-post blog-md clearfix">
								<div class="dlab-post-media"> 
									<a href="/blog">
									<Img fluid={data.factory.childImageSharp.fluid} alt="Plastic Bag Printing" className="img-cover"/>

									</a> 
								</div>
								<div class="dlab-post-info">
									<div class="dlab-post-meta">
										<ul>
											<li class="post-date"> <strong>12 Aug</strong> <span> 2020</span> </li>
											<li class="post-author">By <a href="/blog">Nhat</a> </li>
										</ul>
									</div>
									<div class="dlab-post-title">
										<h4 class="post-title"><a href="/blog">Plastic Packaging in Present Society</a></h4>
									</div>
									<div class="dlab-post-text">
										<p>Plastic packaging currently occupies a very important position in present society.The most outstanding feature of plastic packaging is that it is lighter than all other packaging, very convenient in transportation, distribution. Plastic packaging are colorful and attractive to consumers as it can achieve high quality printing.</p>
									</div>
									<div class="dlab-post-readmore"> 
										<a href="/blog" title="READ MORE" rel="bookmark" class="site-button">Read The Article
											<i class="ti-arrow-right"></i>
										</a>
									</div>
								</div>
							</div> */}
							<div class="pagination-bx clearfix text-center">
								<ul class="pagination">
									<li class="previous"><a href="#" onClick={() => {
										if(curPage >=2){
											setPage(curPage - 1)
										} else{
											setPage(numPages-1)
										}
										}}><i class="ti-arrow-left"></i> Prev</a></li>
									{/* <li class="active"><a href="/blog">{curPage}</a></li>
									 for (var i = 0; i < numrows; i++) {
										// console.log(page)
										return(
											<li class="active"><a href="/blog">{idx}</a></li>
										)
									}
									} */}
									{[...Array(numPages)].map((x, i) =>{
										if(i + 1 === curPage){
											return <li class="active"><a href="#" >{i+1}</a></li>
										}else{
											return <li class="inactive"><a href="#" onClick={() => {
													setPage(i+1)
												}}>{i+1}</a></li>
										}
										
									}
									)}
									<li class="next"><a href="#" onClick={() => {
										console.log(curPage,numPages)
										if(curPage <= numPages-1){
											setPage(curPage + 1)
										}else{
											setPage(1)
										}
										}}>Next <i class="ti-arrow-right"></i></a></li>
								</ul>
							</div>
						</div>
					</div>
                    
				</div>   
				    ) 
					        
                <Footer/>                
            </>
	)
}


export const pageQuery = graphql`
  query {
    page: allMarkdownRemark(
		sort: { order: DESC, fields: [frontmatter___date] }
	)  {
      edges{
		  node{
			frontmatter {
				date(formatString: "MMMM DD, YYYY")
				slug
				title
				author
				tags
				urls
				summary
				featuredImage {
					childImageSharp {
					  fluid(quality: 100) {
						...GatsbyImageSharpFluid
					  }
					}
				  }
				}
				
		  }
	  }
      
    }
	desktop: file(relativePath: { eq: "duynhat/company/topview.jpg" }) {
		childImageSharp {
			fluid(quality: 100, maxWidth: 1920) {
			...GatsbyImageSharpFluid_withWebp
			}
		}
	}
  }
`